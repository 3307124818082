import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  contentTypes: {}
}

// getters
const getters = make.getters(state)

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  ...make.actions(state),

  async fetch({ dispatch }, publicationId) {
    return axios.get(`/api/internal/publications/${publicationId}/community_q/content_types`)
      .then(response => {
        dispatch('setContentTypes', response.data.content_types)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
