import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    file: null
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  exists: ({ file }) => !!file
}

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = make.actions(state)

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
