import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  webCategories: [],
  fetchInitiated: false
}

// getters
const getters = {
  ...make.getters(state),

  all({ webCategories }) {
    return webCategories
  },

  allAsObject({ webCategories }) {
    let obj = {}
    webCategories.forEach(webCategory => {
      if( webCategory.enabled == 1 ){
        obj[webCategory.title] = webCategory.id
      }
    })
    return obj
  },

  forIds: ({ webCategories }) => (ids) => {
    return webCategories.filter(webCat => ids.includes(webCat.id))
  }
}

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return axios.get('/api/internal/web_categories')
      .then(response => {
        commit('SET_WEB_CATEGORIES', response.data.web_categories)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
      })
  },

  async fetchOnce({ dispatch, state: { fetchInitiated } }) {
    if (fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
