import axios from 'axios'

// initial state
const state = []

// getters
const getters = {
  all(state) {
    return state
  }
}

// mutations
const mutations = {
  set(state, bylinePresets) {
    // Replacing the array breaks change detection, so splice and push instead
    state.splice(0, state.length)
    state.push(...bylinePresets)
  }
}

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    return axios.get('/api/internal/community_q/byline_presets')
      .then(response => {
        commit('set', response.data.byline_presets)
      })
      .catch((err) => {
        // We still add the presets. Because the endpoint returns bylines from multiple Publications.
        // Which meanst one could fail and the others could succeed. So we can be in a half successful half failed state.
        commit('set', err?.response?.data?.byline_presets || [])
        dispatch('messages/smartAdd', err, {
          root: true
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
