import Vue from 'vue'
import axios from 'axios'
import { make } from 'vuex-pathify'
import { withIDKeys } from '../../utils/state_helpers'

const getNewState = () => {
  return { id: null, path: '' }
}

const getDefaultState = () => {
  return {
    current: getNewState(),
    customFolders: {}
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  allPaths: ({ customFolders }) => Object.values(customFolders).map((folder) => folder.path),
}

// mutations
const mutations = {
  ...make.mutations(state),

  SET({ customFolders }, customFolder) {
    Vue.set(customFolders, customFolder.id, customFolder)
  },

  REMOVE({ customFolders }, customFolderId) {
    Vue.delete(customFolders, customFolderId)
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  ...make.actions(state),

  resetCurrent({ commit }) {
    commit('SET_CURRENT', getNewState())
  },

  async fetchForStory({ dispatch }, storyId) {
    return axios.get(`/api/internal/stories/${storyId}/custom_folders`)
      .then(response => {
        dispatch('setCustomFolders', withIDKeys(response.data.custom_folders))
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
      })
  },

  async create({ commit, dispatch }, storyId) {
    return axios.post(`/api/internal/stories/${storyId}/custom_folders`, {
      custom_folder: {}
    })
      .then(response => {
        commit('SET', response.data.custom_folder)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  },

  async update({ commit, dispatch, state: { customFolders } }, customFolderId) {
    return axios.patch(`/api/internal/custom_folders/${customFolderId}`, {
      custom_folder: customFolders[customFolderId]
    })
      .then(response => {
        commit('SET', response.data.custom_folder)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  },

  async delete({ commit, dispatch }, customFolderId) {
    return axios.delete(`/api/internal/custom_folders/${customFolderId}`)
      .then(() => {
        commit('REMOVE', customFolderId)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, { root: true })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
