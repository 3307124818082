import { make } from 'vuex-pathify'
import { compact } from 'lodash'
import { snakeKeyed } from '../../utils/params_helpers'
import { timeToEndOfDay } from '../../utils/time_helpers'

const DEFAULT_SEARCH = {
  search: '',
  filterImages: false,
  filterDocuments: false,
  squeueIds: [],
  sectionIds: [],
  from: null,
  to: null
}

// initial state
const state = {
  search: DEFAULT_SEARCH
}

// getters
const getters = {
  ...make.getters(state),

  forQueryParams({ search: { search, squeueIds, sectionIds, from, to, filterImages, filterDocuments } }) {

    const types = compact([
      (filterImages ? 'images' : null),
      (filterDocuments ? 'documents' : null),
    ])

    return snakeKeyed({
      search,
      squeueIds,
      sectionIds,
      from,
      // For the "to" time we want 11:59pm on that day, not 12:00am
      to: timeToEndOfDay(to),
      types
    })
  }
}

// mutations
const mutations = {
  ...make.mutations(state)
}

// actions
const actions = {
  ...make.actions(state)
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
