import Vue from 'vue';
import Vuex from 'vuex';
import ActionCableVue from 'actioncable-vue';
import Pathify from './pathify';

import bylinePresets from './modules/byline_presets.js';
import communityqContentTypes from './modules/communityq_content_types.js';
import communityqPublishing from './modules/communityq_publishing.js';
import communityqStoryTemplates from './modules/communityq_story_templates.js';
import currentUser from './modules/current_user.js';
import customFolders from './modules/custom_folders.js';
import emailExporting from './modules/email_exporting.js';
import exportFormats from './modules/export_formats.js';
import files from './modules/files.js';
import issue from './modules/issue.js';
import issues from './modules/issues.js';
import media from './modules/media.js';
import mediaSearch from './modules/media_search.js';
import mediaSelecting from './modules/media_selecting.js';
import medium from './modules/medium.js';
import messages from './modules/messages.js';
import onlinestatuses from './modules/onlinestatuses.js';
import page from './modules/page.js';
import pages from './modules/pages.js';
import publications from './modules/publications.js';
import pullQuote from './modules/pull_quote.js';
import sections from './modules/sections.js';
import settings from './modules/settings.js';
import squeue from './modules/squeue.js';
import squeues from './modules/squeues.js';
import story from './modules/story.js';
import stories from './modules/stories.js';
import storyBatchOptions from './modules/story_batch_options.js';
import storyFileDrop from './modules/story_file_drop.js';
import storyMedia from './modules/story_media.js';
import storyPlacements from './modules/story_placements.js';
import storyPublications from './modules/story_publications.js';
import storySavedSearches from './modules/story_saved_searches.js';
import storySearch from './modules/story_search.js';
import styleMaps from './modules/style_maps';
import styleMappings from './modules/style_mappings';
import subsites from './modules/subsites';
import transform from './modules/transform';
import transforms from './modules/transforms';
import unrecognizedFiles from './modules/unrecognized_files.js';
import users from './modules/users.js';
import webCategories from './modules/web_categories.js';

Vue.use(Vuex);

Vue.use(
  ActionCableVue,
  {
    debug: true,
    debugLevel: 'error',
    connectionUrl: '/cable',
    connectImmediately: true,
  }
);

export default new Vuex.Store({
  plugins: [ Pathify.plugin ],
  modules: {
    bylinePresets,
    communityqContentTypes,
    communityqPublishing,
    communityqStoryTemplates,
    currentUser,
    customFolders,
    emailExporting,
    exportFormats,
    files,
    issue,
    issues,
    media,
    mediaSearch,
    mediaSelecting,
    medium,
    messages,
    onlinestatuses,
    page,
    pages,
    publications,
    pullQuote,
    sections,
    settings,
    squeue,
    squeues,
    story,
    stories,
    storyBatchOptions,
    storyFileDrop,
    storyMedia,
    storyPublications,
    storyPlacements,
    storySavedSearches,
    storySearch,
    styleMaps,
    styleMappings,
    subsites,
    transform,
    transforms,
    unrecognizedFiles,
    users,
    webCategories
  }
});
