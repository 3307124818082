import axios from 'axios'
import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    storyId: null,
    to: null,
    subject: null,
    notes: null,
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  ...make.actions(state),

  async send({ state, commit, dispatch }) {
    const { to, subject, notes } = state

    return axios.post('/api/internal/emails', {
      email_export: {
        story_id: state.storyId,
        to,
        subject,
        notes,
      }
    }).then(({ data }) => {
      dispatch('messages/addNotice', data.message, { root: true })
      commit('RESET')
      return data
    }).catch(err => {
      dispatch('messages/addError', err, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
