import axios from 'axios';
import { make } from 'vuex-pathify';

// initial state
const state = {
  currentUser: {
    id: null,
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    ext: null,
    remote_phone: '',
    byline: '',
    role_is_limited: true,
    can_set_squeue: false,
    can_change_ownership: false,
    can_access_google_picker: false,
    show_advert_fields: false,
    local_file_path: '',
    assignment_notifications: false,
    default_publication_id: null,
    default_story_saved_search_id: null,
  },
  fetchInitiated: false
};

// getters
const getters = make.getters(state);

// mutations
const mutations = make.mutations(state);

// actions
const actions = {
  async fetch({ commit, dispatch }) {
    commit('SET_FETCH_INITIATED', true)

    return new Promise((resolve, reject) => {
      axios.get(
        '/api/internal/current_user'
      ).then(response => {
        commit('SET_CURRENT_USER', response.data.user)
        resolve(response.data.user)
      }).catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
        reject(err)
      })
    })
  },

  async fetchOnce({ dispatch, state }) {
    if (state.fetchInitiated) return Promise.resolve()

    return dispatch('fetch')
  },

  async update({ commit, dispatch }, params) {
    return axios
      .patch('/api/internal/current_user', {
        user: params
      })
      .then(response => {
        commit('SET_CURRENT_USER', response.data.user);
        dispatch('messages/addNotice', response.data.message, { root: true });
      })
      .catch(err => {
        console.error(err);
        dispatch('messages/addError', err, { root: true });
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
