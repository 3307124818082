import Vue from 'vue'
import axios from 'axios'
import { make } from 'vuex-pathify'
import { xor } from 'lodash'

import { getDefaultPaginationState } from '../shared/pagination'

const getDefaultState = () => {
  return {
    ...getDefaultPaginationState(),
    show: false,
    mediumIds: [],
    searchResults: {}
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = make.getters(state)

// mutations
const mutations = {
  ...make.mutations(state),

  TOGGLE(state, mediumId) {
    Vue.set(state, 'mediumIds', xor(state.mediumIds, [mediumId]))
  },

  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}

// actions
const actions = {
  ...make.actions(state),

  async search({ dispatch, commit }, { search, page, perPage, filterTypes }) {
    commit('SET_MEDIUM_IDS', [])

    return axios.get('/api/internal/media', {
      params: { search, page, per_page: perPage, types: filterTypes }
    }).then(({ data: { media, meta: { page }} }) => {
      dispatch('setSearchResults', media)
      dispatch('setPage', page)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
