import axios from 'axios'
import { make } from 'vuex-pathify'

// initial state
const state = {
  storySavedSearches: []
}

// getters
const getters = make.getters(state)

// mutations
const mutations = make.mutations(state)

// actions
const actions = {
  ...make.actions(state),

  async update({ dispatch, state }, storySavedSearch) {
    return dispatch(
      'setStorySavedSearches',
      state.storySavedSearches.map((search) => {
        if(search.id == storySavedSearch.id) {
          return Object.assign(search, storySavedSearch)
        } else {
          return search
        }
      })
    )
  },

  async fetch({ dispatch }) {
    return axios
      .get('/api/internal/story_saved_searches')
      .then(response => {
        dispatch('setStorySavedSearches', response.data.story_saved_searches)
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  async create({ dispatch, state }, title) {
    const newSearch = {
      title,
      query: `${window.location.pathname}${window.location.search}`
    }

    return axios.post('/api/internal/story_saved_searches', {
      story_saved_search: newSearch
    }).then(response => {
      dispatch(
        'setStorySavedSearches',
        [...state.storySavedSearches, newSearch]
      )
      dispatch('messages/addNotice', response.data.message, { root: true })
    }).catch(err => {
      dispatch('messages/addError', err, { root: true })
    })
  },

  async toggleFavorite({ dispatch, state }, storySavedSearchId) {
    const search = state.storySavedSearches.find(srch => srch.id == storySavedSearchId)
    const value = !search.favorite
    const httpMethod = value ? 'post' : 'delete'

    return axios({
      method: httpMethod,
      url: `/api/internal/story_saved_searches/${storySavedSearchId}/favorite`
    })
    .then(response => {
      dispatch('update', { id: storySavedSearchId, favorite: value })
      dispatch('messages/addNotice', response.data.message, { root: true })
    })
    .catch(err => {
      console.error(err)
      dispatch('messages/addError', err, { root: true })
    })
  },

  async toggleHotQueue({ dispatch, state }, storySavedSearchId) {
    const search = state.storySavedSearches.find(srch => srch.id == storySavedSearchId)

    return axios
      .patch(`/api/internal/story_saved_searches/${storySavedSearchId}`, {
        story_saved_search: {
          hot_queue: !search.hot_queue
        }
      })
      .then(response => {
        dispatch('update', response.data.story_saved_search)
        dispatch('messages/addNotice', response.data.message, { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  },

  async destroy({ dispatch, state }, storySavedSearchId) {
    return axios
      .delete(`/api/internal/story_saved_searches/${storySavedSearchId}`)
      .then(response => {
        dispatch(
          'setStorySavedSearches',
          state.storySavedSearches.filter(search => search.id != storySavedSearchId)
        )
        dispatch('messages/addNotice', response.data.message, { root: true })
      })
      .catch(err => {
        console.error(err)
        dispatch('messages/addError', err, { root: true })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

