import axios from 'axios'
import { make } from 'vuex-pathify'

const getDefaultState = () => {
  return {
    unrecognizedFiles: []
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  ...make.getters(state),

  all({ unrecognizedFiles }) {
    return unrecognizedFiles
  }
}

// mutations
const mutations = {
  ...make.mutations(state),

  RESET(state) { Object.assign(state, getDefaultState()) }
}

// actions
const actions = {
  ...make.actions(state),

  async fetchForStory({ commit, dispatch }, storyId) {
    return axios.get(`/api/internal/stories/${storyId}/unrecognized_files`)
      .then(response => {
        commit('SET_UNRECOGNIZED_FILES', response.data.story_files)
      })
      .catch((err) => {
        dispatch('messages/smartAdd', err, {root: true})
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
