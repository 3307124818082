import { make } from 'vuex-pathify'
import { pickBy, unique } from 'lodash'

// initial state
const state = {
  options: {
    selectedStoryIds: [],
    pubDate: '',
    userId: '',
    sectionId: '',
    squeueId: '',
    webCategoryId: ''
  }
}

// getters
const getters = {
  ...make.getters(state),

  checked: ({ options }) => (id) => { return options.selectedStoryIds.includes(id) },

  storyData({ options }) {
    const { pubDate, userId, sectionId, squeueId, webCategoryId } = options

    return pickBy(
      {
        pub_date: pubDate,
        user_id: userId,
        section_id: sectionId,
        squeue_id: squeueId,
        web_category_id: webCategoryId
      }
    )
  },

  anyChanges(_, { storyData }) {
    return Object.keys(storyData).length > 0
  },

  selectedStoriesCount({ options }) {
    return options.selectedStoryIds.length
  },

  anySelectedStories(_, { selectedStoriesCount }) {
    return selectedStoriesCount > 0
  },
}

// mutations
const mutations = {
  ...make.mutations(state),

  CHECK({ options }, key) {
    if(options.selectedStoryIds.includes(key)) return

    options.selectedStoryIds.push(key)
  },

  UNCHECK({ options }, key) {
    options.selectedStoryIds = options.selectedStoryIds.filter(id => id !== key)
  },

  UNCHECK_ALL({ options }) {
    options.selectedStoryIds = []
  }
}

// actions
const actions = {
  ...make.actions(state)
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
