import Vue from 'vue'

Vue.filter('filePath', function(value) {
  if (value != null) {
    const parts = String(value).split('/');
    parts.pop();
    return `${parts.join('/')}/`;
  }
});

/*
* Filter the file name from the path. Removes the unix timestamp prefix for display
*/
Vue.filter('mediaFileName', function(value) {
  if (value != null) {
    const filename = String(value).split('/').pop();

    // 10 digit unix timestamps cover 2001 to 2286, which is plenty good enough for me.
    return filename.replace(/^[0-9]{10}_/, '')
  }
});

Vue.filter('fileExtension', function(value) {
  if (value != null) {
    return String(value).split('.').pop();
  }
});
